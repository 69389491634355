<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">系统设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{
            stuJson.stu == "add" ? "新增推荐设置" : "修改推荐设置"
          }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="bannerList">
              <div class="FormBox">
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <el-form-item label="视频推荐名称" prop="recommendName">
                      <el-input
                        v-model="baseform.recommendName"
                        size="small"
                        maxlength="20"
                        show-word-limit
                      ></el-input>
                      <!-- <p v-show='baseform.rotationName.length==20' style="color:#f46173">不能超过20个字</p> -->
                    </el-form-item>
                    <el-form-item label="推荐系统" prop="terminal">
                      <el-checkbox-group v-model="baseform.terminal">
                        <el-checkbox label="2">安知培训</el-checkbox>
                        <el-checkbox label="1">APP</el-checkbox>
                        <el-checkbox label="4">安知实习</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="推荐位置" prop="recommendSite">
                      <!-- <el-radio-group
                        v-model="baseform.recommendSite"
                        size="small"
                      >
                        <el-radio  v-for="(item,index) in recommendSites" :key="index" :label="item.value">{{ item.label }}</el-radio>
                      </el-radio-group> -->
                        <el-cascader
                        v-model="baseform.recommendSite"
                        :options="RecommendList"
                        :props="propremend"
                        placeholder="请选择推荐位置"
                        clearable
                        filterable
                        size="small"
                      ></el-cascader>
                    </el-form-item>
                    <el-form-item label="行政区划" prop="areaId">
                      <el-cascader
                        v-model="baseform.areaId"
                        :options="areatreeList"
                        :props="propsarea"
                        placeholder="请选择行政区划"
                        clearable
                        filterable
                        size="small"
                      ></el-cascader>
                    </el-form-item>
                    <el-form-item
                      label="封面图片"
                      prop="picFullPath"
                      :class="[rotationId ? 'item-disabled' : '']"
                      required
                    >
                      <el-upload
                        :on-change="handleAvatarSuccess2"
                        :before-upload="$beforeAvatarUpload"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <el-image
                          :src="
                            baseform.picFullPath || require('@/assets/develop.png')
                          "
                          fit="contain"
                          class="imgCenter"
                        ></el-image>
                      </el-upload>
                      <p style="color:#f46173">APP建议上传尺寸700*240</p>
                    </el-form-item>
                    <el-form-item label="选择视频" prop="kpointvideoName">
                      <el-button
                        size="small"
                        @click="dialogVisible = true"
                        type="text"
                        v-if="!baseform.kpointvideoName"
                        >选择视频</el-button
                      >
                      <div v-else>
                        <span v-text="baseform.kpointvideoName"></span>
                        <el-button
                          style="margin-left:20px"
                          size="small"
                          type="text"
                          @click="
                            watch(kpointVideoId, baseform.kpointvideoName)
                          "
                          >预览</el-button
                        >
                        <el-button
                          style="margin-left:20px; color:#f46173"
                          size="small"
                          type="text"
                          @click="kpointdelete"
                          >删除</el-button
                        >
                      </div>
                    </el-form-item>
                     <el-form-item label="公开课描述" prop="description">
                      <el-input
                        v-model="baseform.description"
                        size="small"
                        maxlength="255"
                        type="textarea"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="推荐状态" prop="enable">
                      <el-select
                        v-model="baseform.enable"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in bannerStateList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="推荐详情"
                      prop="recommendContent"
                      class="form-item"
                      required
                    >
                      <div ref="editor" class="editor" style="width:100%" />
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="doCancel" class="bgc-bv"
                  >取 消</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="doEditSave"
                  >确 定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      top="5%"
      width="80%"
      :center="true"
      title="视频选择"
    >
      <div class="operationControl">
        <div class="searchbox">
          <span title="培训类型" class="searchboxItem ci-full flexcc">
            <span class="itemLabel">培训类型:</span>
            <tree
              ref="tree"
              @eventBtn="childBack"
              :ruleForm="ruleForm"
              size="small"
              @clearParams="clearParams"
              :modal="false"
              typeStu
            />
          </span>
          <div title="课件名称" class="searchboxItem ci-full">
            <span class="itemLabel">课件名称:</span>
            <el-input
              placeholder="请输入课件名称"
              v-model="kpointNames"
              size="small"
              clearable
            ></el-input>
          </div>
          <div title="资源提供者" class="searchboxItem ci-full terminalsSet">
            <span class="itemLabel" style="min-width:120px">资源提供者:</span>
            <el-select
              size="small"
              v-model="compOwnerId"
              remote
              :remote-method="getCompanyList"
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
            >
              <el-option
                v-for="item in CompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </div>
          <div class="btnBox">
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column label width="35px" align="center">
              <template slot-scope="scope">
                <el-radio v-model="radio" :label="scope.row" />
              </template>
            </el-table-column>
            <el-table-column
              label="课件名称"
              align="center"
              show-overflow-tooltip
              prop="kpointName"
            />
            <el-table-column
              label="培训类型"
              align="center"
              show-overflow-tooltip
              prop="trainTypeNamePath"
            />
            <el-table-column
              label="资源提供方"
              align="center"
              show-overflow-tooltip
              prop="compName"
            />
            <el-table-column
              label="操作"
              align="center"
              width="100px"
              fixed="right"
            >
              <div slot-scope="scope" class="flexcc">
                <el-button
                  type="text"
                  size="mini"
                  style="padding:0 15px"
                  @click="watch(scope.row.kpointVideoId, scope.row.kpointName)"
                  >预览</el-button
                >
              </div>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
      <span class="dialog-footer">
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import E from "wangeditor";

import List from "@/mixins/List";
import tree from "@/components/treePopup";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
let editor = {};
export default {
  name: "operate/videoedit",
  components: {
    tree,
    PageNum,
    Empty,
  },
  mixins: [List],
  data() {
    return {
      rotationId: "",
      dialogVisible: false,
      stuJson: {
        stu: this.$route.query.stu,
      },
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propremend: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 轮播图状态
      bannerStateList: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],
      // 基本信息
      baseform: {
        recommendName: "", //视频名称
        terminal: [], //推荐系统
        recommendSite: "", //推荐位置
        areaId: "", // 行政区划
        picPath: "", //图片地址
        kpointvideoName: "", //视频
        picFullPath: "", //上传的图片，
        enable: true, // 推荐状态
        recommendContent: "",
      },
      // 获取推荐位置数据
      recommendSites:[],
      rules: {
        recommendName: [
          { required: true, trigger: "blur", message: "请输入视频推荐名称" },
        ],
        terminal: [
          { required: true, trigger: "change", message: "请选择推荐系统" },
        ],
        recommendSite: [
          { required: true, trigger: "blur", message: "请选择推荐位置" },
        ],
        areaId: [
          { required: true, trigger: "blur", message: "请选择行政区划" },
        ],
        picFullPath: [
          { required: true, trigger: "change", message: "请上传封面图片" },
        ],
        kpointvideoName: [
          { required: true, trigger: "change", message: "请选择视频" },
        ],
        description: [
          { required: true, trigger: "blur", message: "请输入公开课描述" },
        ],
        enable: [
          { required: true, trigger: "change", message: "请选择推荐状态" },
        ],
        recommendContent: [
          { required: true, message: "请输入推荐详情", trigger: "change" },
        ],
      },
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: "",
      },
      CompanyList: [],
      compOwnerId: "",
      radio: "",
      kpointVideoId: "",
      kpointId: "",
      kpointName: "",
      kpointNames: "",
      RecommendList:[],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.recommendId = this.$route.query.id;
      this.getDetail();
    }
    this.getareatree();
    this.getRecommendList();
    this.getRecommendedLocation();
  },
  mounted() {
    this.editorInit();
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    /* 资源提供者 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
       if (this.kpointNames) {
        params.kpointName = this.kpointNames;
      }
      if (this.compOwnerId) {
        params.compId = this.compOwnerId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.radio = "";
      this.doFetch({
        url: "/run/courseware/listPage",
        params,
        pageNum,
      });
    },
    /* 视频选择确认 */
    doOk() {
      const { radio } = this;
      if (radio) {
        // this.$emit("eventBus", radio);
        console.log(radio);
        this.baseform.kpointvideoName = radio.kpointName;
        this.kpointVideoId = radio.kpointVideoId;
        this.kpointId = radio.kpointId;
        this.dialogVisible = false;

        this.$refs["baseform"].clearValidate("kpointvideoName");
      } else {
        this.$message({
          message: "请选择要关联的视频",
          type: "warning",
        });
      }
    },
    /* 预览 */
    watch(kpointVideoId, kpointName) {
      this.kpointVideoId = kpointVideoId;
      this.baseform.kpointvideoName = kpointName;
      this.$store.dispatch("handleVideoUrl", {
        kpointVideoId: this.kpointVideoId,
        kpointName: this.baseform.kpointvideoName,
      });
    },
    /* 删除 */
    kpointdelete() {
      console.log(123456);
      // console.log(this.baseform.kpointvideoName);
      this.kpointVideoId = "";
      this.baseform = {
        ...this.baseform,
        kpointvideoName: ''
      }
    },
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.baseform.picFullPath = result.data.fileURL;
          that.baseform.picPath = result.data.fileKey;
          console.log(that.baseform.picFullPath);
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //点击编辑获取数据
    getDetail() {
      this.$post("/sys/recommend/id", {
        recommendId: this.$route.query.id,
      })
        .then((res) => {
          const baseform = res.data || {};
          this.baseform = {
            ...baseform,
          };
          this.baseform.terminal = baseform.terminal.split(",");
          editor.txt.html(baseform.recommendContent);
          this.baseform.kpointvideoName = baseform.kpointName;
          this.kpointVideoId = baseform.kpointVideoId;
        })
        .catch(() => {});
    },
    // 编辑保存
    doEditSave() {
      const params = {
        recommendName: this.baseform.recommendName, //
        areaId: this.baseform.areaId || "", //行政区划
        terminal: this.baseform.terminal.toString(), // 发布系统
        recommendSite: this.baseform.recommendSite, // 轮播图状态
        // pic: this.baseform.picFullPath, //轮播图片
        picPath: this.baseform.picPath, //轮播图片地址
        kpointId: this.kpointId,
        enable: this.baseform.enable,
        recommendContent: this.baseform.recommendContent,
        description: this.baseform.description,
      };
      if (this.$route.query.id) {
        params.recommendId = this.$route.query.id;
      }
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          if (editor.txt.text().trim() == "") {
            this.$message.warning("请输入推荐详情");
            return;
          }
          this.$post(
            this.$route.query.id
              ? "/sys/recommend/modify"
              : "/sys/recommend/insert",
            params
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.$router.push({
                  path: "/web/operate/videolist",
                  query: {
                    refresh: true,
                  },
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 点击取消
    doCancel() {
      this.$router.back();
    },
    // 富文本编辑器
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.baseform.recommendContent = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        const list = [{ value: "-10000", label: "全部区域" }];
        for (const key in ret.data) {
          list.push({
            value: ret.data[key].value,
            label: ret.data[key].label,
            children: ret.data[key].children,
          });
        }
        this.areatreeList = list;
      });
    },
    getRecommendList() {
      this.$post("/biz/recommend/site/tree",{level:2}).then((ret) => {
       let list = []
        for (const key in ret.data) {
          list.push({
            id: ret.data[key].id,
            label: ret.data[key].label,
            children: ret.data[key].children,
          });
        }
        this.RecommendList = list;
        console.log(this.RecommendList );
      });
    },
    /* 弹框取消 */
    dialogClose() {
      this.dialogVisible = false;
      this.compOwnerId = "";
      this.kpointNames = "";
      this.params = {};
      this.ruleForm = {
        Trainingtype: "",
      };
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 获取推荐位置
     getRecommendedLocation() {
      const CrowdtypeList = this.$setDictionary("RECOMMENDSITE", "list");
      const list = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key]
        });
      }
      this.recommendSites = list;
    },
  },
};
</script>
<style lang="less" scoped>
.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .FormBox {
    flex: 1;
  }
}

.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
}
.dialog-footer /deep/ {
  display: flex;
  justify-content: center;
}
</style>
